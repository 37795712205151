import $http from '@/plugin/axios'

const state = {}
const getters = {}
const mutations = {
  update: (state, { key, value }) => (state[key] = value)
}
const actions = {
  pickImageList({}, count = 9) {
    return new Promise((resolve, reject) => {
      let input = document.querySelector('#imageUploader')
      if (!input) {
        input = document.createElement('input')
        input.setAttribute('id', 'imageUploader')
        input.setAttribute('type', 'file')
        input.setAttribute('accept', 'image/*')
        input.setAttribute('multiple', true)
        input.style = 'width:0;height:0;display:none;'
        document.body.appendChild(input)
        input.addEventListener('input', e => {
          if (e.target.files.length > count) {
            input.remove()
            reject({ msg: `不能选择超过${count}个文件` })
          } else {
            Promise.all(
              [...e.target.files].map(file => {
                const formData = new FormData()
                formData.append('file', file)
                return $http.post('/upload/image', formData)
              })
            ).then(res => {
              input.remove()
              resolve(res)
            })
          }
        })
      }
      input.click()
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
