export function imageThumb(val, size = 200) {
  return val + `?x-oss-process=image/resize,w_${size}`
}
export function formatGenderIcon(val) {
  return { male: 'mdi-gender-male', female: 'mdi-gender-female', other: 'mdi-gender-male-female' }[
    val
  ]
}
export function formatGenderColor(val) {
  return { male: 'indigo', female: 'pink', other: 'grey' }[val]
}
export function formatCameramanTagColor(val) {
  return { 广告摄影: 'lime lighten-4', 商城摄影: 'deep-orange lighten-4' }[val]
}
export function formatTeamColor(val) {
  return { 策划: '#F3E5F5', 共同开发: '#E3F2FD', 兼职策划: '#F9FBE7' }[val]
}
export function sliceString(val, length = 15) {
  return val.toString().substring(0, length)
}
export function formatScore(val) {
  return Number(val).toFixed(2)
}
export function formatFullDate(val) {
  if (val) {
    if (String(val).length === 10) {
      val = val * 1000
    }
    let date = new Date(val)
    return (
      date.getFullYear() +
      '-' +
      (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
      '-' +
      (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
      ' ' +
      (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
      ':' +
      (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
    )
  } else {
    return '-'
  }
}
