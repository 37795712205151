import Vue from 'vue'
import axios from './axios'
import './component'
import './filter'
import './directive'
import './vant'
import * as funcList from './func'

Object.keys(funcList).forEach(func => {
  Vue.prototype['$' + func] = funcList[func]
})
Vue.prototype.$http = axios
