<!--
 * @Author: Link
 * @Date: 2020-12-15 10:43:06
 * @LastEditTime: 2021-06-04 10:32:58
-->
<template>
  <div class="topbar" :style="`background:${background};color:${color};`" v-if="!isMiniApp">
    <template>
      <div @click="back" v-if="isback" style="width: fit-content">
        <van-icon name="arrow-left" class="icon" size="20px" />
      </div>
      <div class="title">{{ title }}</div>
    </template>
  </div>
  <div v-else></div>
</template>

<script>
export default {
  props: {
    background: {
      type: String,
      default: '#e7141a'
    },
    color: {
      type: String,
      default: '#fff'
    },
    title: {
      type: String,
      default: 'title'
    },
    isback: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isMiniApp() {
      return this.$store.getters['sys/isBmMiniApp']
    }
  },
  data() {
    return {
      showPopover: false,
      actions: [
        { text: '退出登录', index: 0 },
        { text: '刷新', index: 1 }
      ]
    }
  },
  methods: {
    back() {
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
.topbar {
  position: sticky;
  top: 0;
  left: 0;
  color: #fff;
  background: #e7141a;
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px;
  margin: 0;
  .icon {
    margin-top: 5px;
  }
  .title {
    font-size: 20px;
    font-weight: bold;
    flex: 1;
    text-align: center;
  }
}
</style>
