import $http from '@/plugin/axios'

const state = {
  userInfo: {},
  adminInfo: {
    name: ''
  },
  authList: [],
  banList: []
}
const getters = {
  userInfo: state => state.userInfo || {},
  userId: state => state.userInfo.id,
  isLogin: state => !!state.userInfo.id,
  isBan: state => state.banList.includes(state.userInfo.id)
}
const mutations = {
  userInfo: (state, payload) => (state.userInfo = payload) || {},
  update: (state, { key, value }) => {
    state[key] = value
  }
}
const actions = {
  loginByToken({ commit, dispatch }, token) {
    localStorage.token = token
    return $http
      .post('/account/loginByToken')
      .then(userInfo => {
        commit('update', {
          key: 'userInfo',
          value: userInfo
        })
        return dispatch('getUserAuthList')
      })
      .catch(err => {
        return dispatch('logout')
      })
  },
  logout({ commit }) {
    localStorage.removeItem('token')
    commit('update', {
      key: 'userInfo',
      value: { id: 0, name: '' }
    })
  },
  getBanList({ commit }) {
    $http
      .get(
        'https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/miniapp/banList.json?time=' +
          new Date().getTime()
      )
      .then(res => {
        commit('update', {
          key: 'banList',
          value: res || []
        })
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
