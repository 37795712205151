<template>
  <div class="componentContainer">
    <span> this is a vue component file with basic api </span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    foo: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    bar: 'bar'
  }),
  computed: {
    ...mapGetters({})
  },
  watch: {},
  methods: {
    ...mapActions({})
  },
  created() {},
  mounted() {}
}
</script>

<style lang="less" scoped>
.componentContainer {
  width: 100%;
}
</style>
