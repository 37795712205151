import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  modules: ['user']
})

let modules = {}
const moduleList = require.context('@/store', false, /\.js$/)
moduleList.keys().forEach(fileName => {
  const moduleName = fileName.substring(2, fileName.length - 3)
  modules[moduleName] = moduleList(fileName).default
})

export default new Vuex.Store({
  modules,
  plugins: [vuexLocal.plugin]
})
