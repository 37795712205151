/*
 * @Author: Link
 * @Date: 2020-12-15 09:15:19
 * @LastEditTime: 2020-12-15 10:49:08
 */
import Vue from 'vue'
import App from '../App.vue'
import '@/plugin'
import routerGenerate from './router'
import store from '@/plugin/store'
import topBar from '../component/topbar'

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.component('topbar', topBar)
Vue.config.productionTip = false

export default function ({ routerBase, routerList }) {
  const router = routerGenerate(routerBase, routerList)
  return new Vue({
    store,
    router,
    render: h => h(App)
  }).$mount('#app')
}
