import Vue from 'vue'
import store from '@/plugin/store'

Vue.directive('bg', {
  inserted: (el, bind) => {
    el.style.backgroundImage = `url(${bind.value})`
    el.style.backgroundSize = `contain`
    el.style.backgroundRepeat = `no-repeat`
    el.style.backgroundPosition = `center`
  }
})

Vue.directive('auth', {
  inserted: (el, bind) => {
    const authList = store.getters['user/authList']
    const authCodeList = bind.value.split(',')
    if (!authList.some(a => authCodeList.includes(a))) {
      el.remove()
    }
  }
})
