import Vue from 'vue'
import Router from 'vue-router'
import $store from '@/plugin/store'

Vue.use(Router)
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => {
    if (error.name !== 'NavigationDuplicated') {
      console.log('>>>>>>> error', error)
    }
  })
}

export default function(base, routes) {
  const router = new Router({
    mode: 'history',
    base,
    routes
  })
  router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.requireAuth) {
      let isLogin = $store.getters['user/isLogin']
      isLogin=true
      if (!isLogin) {
        next('/')
      } else {
        next()
      }
    } else {
      next()
    }
  })
  router.afterEach(to => {
    if (to.meta && to.meta.title) {
      document.title = to.meta.title
    }
  })

  return router
}
