const state = {
  colorList: ['#ffa502', '#ff6348', '#ff4757', '#2ed573', '#1e90ff']
}
const getters = {
  isDev: () => process.env.NODE_ENV === 'development',
  randomColor: state => () => state.colorList[Math.floor(Math.random() * state.colorList.length)],
  today: () => new Date().toISOString().substr(0, 10),
  isBmMiniApp: () =>
    window.__wxjs_environment === 'miniprogram' ||
    /miniProgram/i.test(navigator.userAgent.toLowerCase()),
  isWx: () => {
    let ua = navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true
    }
    return false
  }
}
const mutations = {
  update: (state, { key, value }) => (state[key] = value)
}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
