<template>
  <div id="app">
    <router-view />
    <van-dialog v-model="loging" :show-confirm-button="false">
      <div style="padding: 30px">
        <van-loading type="spinner" :vertical="true">登录中...</van-loading>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Notify, Dialog } from 'vant'
export default {
  data() {
    return {
      loging: false,
      url: ''
    }
  },
  created() {
    try {
      let url = decodeURIComponent(document.location.href)
      let paramStr = url.split('?')[1]?.split('&') || []
      let params = {}
      paramStr.map(item => {
        let temp = item.split('=')
        params[temp[0]] = temp[1]
      })
      
      if(localStorage['tenantId'] && localStorage['tenantId'] != params['tenantId']){
        localStorage.clear()
      }

      let token = params['bmtoken']
      let inviteId = params['inviteId']
      let userId = params['userId']
      let phone = params['phone'] || ''
      localStorage['areaName'] = params['areaName'] || ''
      localStorage['areaId'] = params['areaId'] || -1
      if (params['tenantId']) {
        localStorage['tenantId'] = params['tenantId'] || 0
      }

      if (inviteId) {
        this.$router.replace({ path: '/', query: { id: inviteId, userId, phone } })
        return
      }
      if (token) {
        this.loging = true
        this.$http
          .post('boom-center-user-service/app/distributionMember/convertToToken', { token })
          .then(res => {
            if (res.token) {
              localStorage['token'] = res.token
              this.$store.commit('user/userInfo', res.distributionMemberInfo)
              this.$router.replace('/home')
            }
          })
          .catch(err => {
            Dialog.alert({
              message: err.msg || '服务器內部错误'
            }).then(() => {
              if (err.code == 2006) {
                this.$store.dispatch('user/logout')
              } else {
                localStorage.removeItem('token')
                this.$store.commit('user/update', {
                  key: 'userInfo',
                  value: { id: 0, name: '' }
                })
              }
            })
          })
          .finally(() => (this.loging = false))
      }
    } catch (err) {
      console.log('>>>>>>> err', err)
    }
  }
}
</script>

<style lang="less">
@import '~@/style/reset.less';
@import '~@/style/global.less';
.van-loading {
  text-align: center;
}
</style>
