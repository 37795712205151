const isDev = process.env.NODE_ENV === 'development'

export function log(...arg) {
  if (isDev) {
    console.log(
      '%cdebug%cinfo',
      'background-color: #333;color:white;padding:2px 6px;border-radius:3px 0 0 3px',
      'background-color: #e67e22;color:white;padding:2px 6px;border-radius:0 3px 3px 0',
      ...arg
    )
  }
}

export function logAxiosSuccess(response) {
  if (isDev) {
    console.groupCollapsed(
      `🆗%c>>>>>>> ${response.config.method.toUpperCase()} ${response.config.url.replace(
        response.config.baseURL,
        '/'
      )}`,
      'color:green'
    )
    if (response.config.params) {
      console.log('params: ', response.config.params)
    }
    if (response.data.list) {
      if (response.data.page) {
        console.log('page: ', response.data.page)
      }
      console.log('list: ', response.data.list)
    } else {
      console.log('response: ', response.data.data || response.data)
    }
    console.groupEnd()
  }
}
export function logAxiosError(response) {
  if (isDev) {
    console.groupCollapsed(
      `❌%c>>>>>>> ${response.config.method} ${response.config.url}`,
      'color:red'
    )
    console.log(response.data)
    console.groupEnd()
  }
}
