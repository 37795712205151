import axios from 'axios'
import { logAxiosSuccess, logAxiosError } from '@/util/log'
import store from '@/plugin/store'
import { Notify } from 'vant'
import router from './router'

const baseURL = process.env.VUE_APP_baseUrl

let config = {
  baseURL: baseURL,
  timeout: 60 * 1000,
  withCredentials: false,
  headers: {
    'Tenant-Id': process.env.VUE_APP_TenantId
  }
}

const instance = axios.create(config)

instance.interceptors.request.use(
  config => {
    if (localStorage['token']) {
      config.headers['Bm-Member-Token'] = localStorage['token']
    }
    config.headers['Tenant-Id'] = localStorage['tenantId'] || config.headers['Tenant-Id'] || 1
    if (config.params) {
      delete config.params.total
      delete config.params.isFinish
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  response => {
    if (response.headers['Bm-New-Token']) {
      localStorage['token'] = response.headers['Bm-New-Token']
    }
    if (response.headers['new-bm-member-token']) {
      localStorage['token'] = response.headers['new-bm-member-token']
    }
    if (response.request.responseURL.includes('bm-mall-file.oss-cn-shenzhen')) {
      return response.data
    }
    if (response.data.code === 0) {
      logAxiosSuccess(response)
      if (response.data.list) {
        let page = response.data.page
        if (page) {
          page.isFinish = page.page * page.size >= page.total
        }
        return { page, list: response.data.list }
      }
      return response.data.data || response.data
    } else if (response.data.code === 2001 || response.data.code === 2003) {
      Notify({ type: 'warning', message: '登陆信息有误' })
      store.dispatch('user/logout')
    } else if (response.data.code === 5000 || response.data.code === 3000) {
      if (
        !response.request.responseURL.includes('get_member_simple_info') &&
        !response.request.responseURL.includes('/boom-center-user-service/app/personal/shop')
      ) {
        Notify({ type: 'danger', message: response.data.msg })
      }
      if (response.data.msg === '分销会员不存在') {
        store.dispatch('user/logout')
      }
      return Promise.reject(response.data)
    } else {
      logAxiosError(response)
      return Promise.reject(response.data)
    }
  },
  error => {
    if (error?.response?.status === 401) {
      Notify({ type: 'warning', message: '请登陆' })
    } else if (error?.response?.code === 5001) {
      Notify({ type: 'warning', message: error.response.msg, duration: 5000 })
    }
    return Promise.reject(error)
  }
)

export default instance
