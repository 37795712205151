/*
 * @Author: Link
 * @Date: 2020-11-16 13:46:20
 * @LastEditTime: 2021-07-15 16:59:36
 */
import bootstrap from '@/plugin/bootstrap'

bootstrap({
  routerBase: '/',
  routerList: [
    {
      path: '/',
      component: () => import('@/entryIndex/layout/default'),
      children: [
        {
          path: '/',
          meta: {
            title: '登录'
          },
          component: () => import('@/entryIndex/page/index')
        },
        {
          path: '/home',
          meta: {
            title: '个人中心',
            requireAuth: true
          },
          component: () => import('@/entryIndex/page/home/index')
        },
        {
          path: '/notice',
          meta: {
            title: '',
            requireAuth: false
          },
          component: () => import('@/entryIndex/page/home/notice')
        },
        {
          path: '/leaderBoard',
          meta: {
            title: '排行榜',
            requireAuth: true
          },
          component: () => import('@/entryIndex/page/leaderBoard/index')
        },
        {
          path: '/gradeDesc',
          meta: {
            title: '等级说明',
            requireAuth: true
          },
          component: () => import('@/entryIndex/page/gradeDesc/index')
        },
        {
          path: '/withdraw',
          meta: {
            title: '提现',
            requireAuth: true
          },
          component: () => import('@/entryIndex/page/withdraw/index')
        },
        {
          path: '/shareCode',
          meta: {
            title: '邀请合伙人',
            requireAuth: true
          },
          component: () => import('@/entryIndex/page/shareCode/index')
        },
        {
          path: '/myTeam/all',
          meta: {
            title: '引流详情',
            requireAuth: true
          },
          component: () => import('@/entryIndex/page/myTeam/all')
        },
        {
          path: '/myTeam/share',
          meta: {
            title: '推广详情',
            requireAuth: true
          },
          component: () => import('@/entryIndex/page/myTeam/share')
        },
        {
          path: '/income',
          meta: {
            title: '收益明细',
            requireAuth: true
          },
          component: () => import('@/entryIndex/page/income/index')
        },
        {
          path: '/setting',
          meta: {
            title: '设置',
            requireAuth: true
          },
          component: () => import('@/entryIndex/page/setting/index')
        },
        {
          path: '/userSign',
          meta: {
            title: '签约设置',
            requireAuth: true
          },
          component: () => import('@/entryIndex/page/userSign/index')
        },
        {
          path: '/withdraw',
          meta: {
            title: '体现页',
            requireAuth: true
          },
          component: () => import('@/entryIndex/page/withdraw/index')
        },
        {
          path: '/withdraw/setting',
          meta: {
            title: '提现设置',
            requireAuth: true
          },
          component: () => import('@/entryIndex/page/withdraw/setting')
        },
        {
          path: '/withdraw/success',
          meta: {
            title: '提现成功',
            requireAuth: true
          },
          component: () => import('@/entryIndex/page/withdraw/success')
        },
        {
          path: '/withdraw/list',
          meta: {
            title: '提现列表',
            requireAuth: true,
            keepAlive: true
          },
          component: () => import('@/entryIndex/page/withdraw/recordList')
        },
        {
          path: '/withdraw/detail',
          meta: {
            title: '提现详情',
            requireAuth: true
          },
          component: () => import('@/entryIndex/page/withdraw/recordDetail')
        },
        {
          path: '/setting/addBankCard',
          meta: {
            title: '添加收款账户',
            requireAuth: true
          },
          component: () => import('@/entryIndex/page/withdraw/addBankCard')
        },
        {
          path: '/setting/logoff',
          meta: {
            title: '注销',
            requireAuth: true
          },
          component: () => import('@/entryIndex/page/setting/logoff')
        },
        {
          path:'/protocol',
          meta:{
            title:'协议',
            requireAuth:true
          },
          component:()=>import('@/entryIndex/page/protocol/index')
        }
      ]
    }
  ]
})
